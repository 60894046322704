// import logo from './logo.svg';
import "./App.scss";
import "./responsive.scss";
import Home from "./screens/Home.js";
import OnBoardingFlow from "./screens/OnBoardingFlow";
import Signup from "./screens/Signup";
import SignIn from "./screens/SignIn.js";
import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { PageLoader } from "./auth0/page-loader";
import { getAuth, onAuthStateChanged } from "firebase/auth";


import Page404 from "./screens/Page404";
import Lxpaccounts from "./screens/Accountscreens.js";
import RegisterForm from "./components/RegisterForm.jsx";
import VerifyEmail from "./screens/VerifyEmail.js";
import { ApiRequests } from "./service/ApiRequests.js";
import { auth } from "./config/firebase.js";
import Actions from "./screens/Actions.jsx";
import Organzation from "./screens/Organzation.jsx";
import Authors from "./screens/Authors.jsx";
import AccessDenied from "./screens/AccessDenied.jsx";
import Site from "./screens/Site.jsx";
import NoFound from "./screens/NoFound.jsx";
import NoStudentFound from "./screens/NoStudentFound.jsx";
import ComingSoon from "./screens/ComingSoon.jsx";
import PageNot from "./screens/PageNot.jsx";
import CreateAuthor from "./screens/CreateAuthor.js";



export default function App() {
  const [loading, setLoading] = useState(true)
  const location = useLocation();

  const [user, setUser] = useState(null);
  const fetchToken = async (uri) => {
    try {
      const { data } = await ApiRequests.getCustomToken({
        uid: auth.currentUser.uid,
      });
      localStorage.removeItem('redirect');
      window.location.href = uri + "?token=" + data.customToken;
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };
  useEffect(() => {
    // set redirect
    const params = new URLSearchParams(location.search);
    const redirectPath = params.get("redirect");
    const invite = params.get("invite");
    const authorInvite = params.get("author-invite");
    if (redirectPath) {
      localStorage.setItem('redirect', redirectPath);
    }
    if (invite) {
      localStorage.setItem('invite', invite);
    }
    if (authorInvite) {
      localStorage.setItem('author-invite', authorInvite);
    }
    const auth = getAuth();
    setLoading(true)
    // Create an observer to listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in
        setUser(user);
        setToken(user)
        const uri = localStorage.getItem('redirect');
        if (uri) {
          fetchToken(uri);
        }
        // if (!window.location.href.includes('signup'))
        //   navigate('/signup/profile');

      } else {
        // User is signed out
        setUser(null);
      }
      setLoading(false);
    });

    // Cleanup the observer when the component is unmounted
    return () => unsubscribe();
  }, []);
  const setToken = async (user) => {
    try {
      localStorage.setItem('app-access-token-auth0', user.accessToken);
      setTimeout(() => {
        setLoading(false)
      }, 100);
    } catch (error) {
      setLoading(false)
    }
  }



  if (loading)
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/find" element={<Home />} />
      <Route path="/accounts" element={<Lxpaccounts />} />
      <Route path="signin" element={<SignIn />} />
      <Route path="actions" element={<Actions />} />
      <Route path="signup" element={<Signup />} />
      <Route path="verify" element={<VerifyEmail />} />
      <Route path="signup/email" element={<RegisterForm />} />
      <Route path="organizations" element={<Organzation />} />
      <Route path="access" element={<AccessDenied />} />
      <Route path="site" element={<Site />} />
      <Route path="notfound" element={<NoFound />} />
      <Route path="notstudentfound" element={<NoStudentFound />} />
      <Route path="comingsoon" element={<ComingSoon />} />
      <Route path="page" element={<PageNot />} />
      <Route path="authors" element={<Authors />} />
      <Route path="authors/create" element={<CreateAuthor />} />
      {user && (
        <>
          {/* <Route path="signup/profile" element={<Profile />} /> */}
          <Route
            path="signup/organization"
            element={<OnBoardingFlow />}
          />
        </>
      )
      }
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
