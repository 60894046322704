import React from 'react'

const VerifyEmail = () => {
    return (
        <div className='accounts-body'>
            <img className='accounts-logo' src={require('../images/logo/logo.png')} />
            <div className='create-account-card'>
                <h1 className='mb-0'>Verify Email</h1>
                <div className='terms-privacy'>
                    We have sent out a confirmation code to bahaji@ilmiya.com
                </div>
                <div className='form-card'>
                    <div className='form-code'>
                        <input type="text" className='form-control' />
                        <input type="text" className='form-control' />
                        <input type="text" className='form-control' />
                        <input type="text" className='form-control' />
                        <input type="text" className='form-control' />
                        <input type="text" className='form-control' />
                    </div>
                </div>
                <button className='btn btn-primary'>
                    Confirm
                </button>
                <div className='already-have-account'>
                    Didn't receive code?<a href=''> Resend code <i className="fa-regular fa-arrow-right"></i></a>
                </div>
                <div className='help-center mt-5'>
                    <a href='https://docs.ilmiya.com'>Help</a>
                    <span>|</span>
                    <a href='https://ilmiya.com/legal/privacy-policy'>Privacy</a>
                    <span>|</span>
                    <a href='https://ilmiya.com/legal/terms-of-service'>Terms</a>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmail