import React from "react";
import { auth } from "../config/firebase";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const { currentUser: user } = auth;
  const navigate = useNavigate();

  return user ? (
    <>
      {/* {user.emailVerified ? (
        ""
      ) : (
        <button
          type="button"
          onClick={() => {
            user
              .sendEmailVerification()
              .then((d) => {
                alert("check email");
                console("check email", d);
              })
              .catch((err) => console.log("sendSignInLinkToEmail:", err));
          }}
          className="logout btn btn-success"
          style={{ position: "absolute", top: "11px", right: "90px" }}
          id="logoutButton"
        >
          <i className="fa-regular fa-square-check"></i>
        </button>
      )} */}
      <button
        type="button"
        onClick={() => {
          auth.signOut().then(() => navigate("/"));
        }}
        className="logout btn btn-danger"
        style={{ position: "absolute", top: "11px", right: "32px" }}
        id="logoutButton"
      >
        <i className="fa-regular fa-arrow-right-from-bracket"></i>
      </button>
    </>
  ) : (
    ""
  );
}
